<template>
  <ul class="flex flex-col">
    <li class="text-black text-xl font-semibold p-2 mt-2">Ziele definieren</li>
    <li class="flex flex-col w-full rounded border card">
      <a
        href=""
        @click.prevent="
          $emit('current', current === $options.name ? null : $options.name)
        "
        class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold card-header"
        >Ziele definieren</a
      >
      <div v-if="current === $options.name" class="flex flex-col w-full p-2">
        <div class="flex self-end space-x-2">
          <button
            class="rounded-sm px-2 py-1 bg-blue-600 hover:bg-blue-800 text-white save-btn-small"
            :class="isAssesment ? 'rounded p-1 bg-blue-600' : 'rounded p-1 bg-green-400'"
            @click.prevent="addGoal"
          >
            Neues Ziel festlegen
          </button>
        </div>

        <ul class="flex flex-col space-x-2 space-y-2 my-1 list-none">
          <li class="flex space-x-2 font-semibold m-0">
            <h3 class="w-1/4 px-2">Bezeichnung</h3>
            <h3 class="w-7/12 px-2">Zielformulierung nach SMART</h3>
          </li>
          <li
            class="flex space-x-2 font-semibold m-0"
            v-for="(row, index) in $v.data.$each.$iter"
            :key="`goal-${index}`"
          >
            <div class="w-1/4">
              <input
                type="text"
                v-model="row.name.$model"
                class="border rounded px-2 py-1 w-full"
              />
            </div>
            <div class="w-7/12">
              <input
                type="text"
                v-model="row.description.$model"
                class="border rounded px-2 py-1 w-full"
              />
            </div>
            <div class="w-1/12">
              <button
                class="rounded px-2 py-1 bg-red-600 text-white"
                @click.prevent="data.splice(index, 1)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { required } from "vuelidate/lib/validators";

const tempGoal = {
  name: "",
  description: "",
};

export default {
  name: "review-custom-goals",
  props: ["rows", "current", "scales", "isAssesment", "who", "marks"],
  data() {
    return {
      data: [],
    };
  },
  validations: {
    data: {
      $each: {
        name: {
          required,
        },
        description: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.rows) this.data.push(...this.rows);
      else this.data = [{ ...tempGoal }];

      // check if this.data is empty
      //if(this.data.length === 0) this.data.push({ ...tempGoal })
    },
    addGoal() {
      this.data.push({
        ...tempGoal,
      });
    },
  },
  watch: {
    data: {
      handler: function (n) {
        if (this.isAssesment) {
          this.$emit("error", {
            key: "goals",
            value: n.length === 0 ? true : this.$v.$invalid,
            error: "Bitte definieren Sie Ihre Ziele",
          });
          this.$emit('update', { key: 'goals', value: n })
          
        } else {
          this.$emit("error", {
            key: "goals",
            value: this.$v.$invalid,
            error: "Bitte definieren Sie Ihre Ziele",
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
